<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-dialog v-model="dialogSpecies" persistent :overlay-opacity="1">
      <v-card class="pa-4 rounded-lg" min-height="100" max-height="700">
        <!-- <a-icon
          type="close"
          class="fn18"
          style="position: absolute; right: 20px"
          @click="cancel()"
        /> -->
        <div
          class="text-center mt-4"
          style="font-size: 32px; color: #07204b; font-family: OpenSans"
        >
          ยินดีต้อนรับ
          <img
            src="@/assets/biah-member-club-logo.png"
            class="my-2 rounded-circle"
            style="display: block; margin-left: auto; margin-right: auto"
            width="80"
            height="80"
          />
          <div class="fn20" v-if="userData">
            {{ userData.firstname }} {{ userData.lastname }}
            <!-- <v-icon class="mt-n4"> mdi-square-edit-outline </v-icon> -->
          </div>
          <div class="fn20">Line ID: {{ lineId }}</div>
          <div class="fn16 mt-4">
            กรุณาเลือกธุรกิจที่คุณสนใจ <br />
            เพื่อรับข้อมูลข่าวสารต่างๆ
          </div>
          <v-row class="mt-4">
            <div class="InputGroup">
              <input
                type="radio"
                name="size"
                hidden
                id="size_1"
                value="ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง"
                v-model="selectSpecies"
              />
              <label for="size_1" class="mt-4 pt-3 rounded-lg fn16"
                >ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง</label
              >

              <input
                type="radio"
                name="size"
                hidden
                id="size_2"
                value="ธุรกิจสัตว์ปีก"
                v-model="selectSpecies"
              />
              <label for="size_2" class="mt-4 pt-3 rounded-lg fn16"
                >ธุรกิจสัตว์ปีก</label
              >

              <input
                type="radio"
                name="size"
                hidden
                id="size_3"
                value="ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป"
                v-model="selectSpecies"
              />
              <label for="size_3" class="mt-4 pt-3 rounded-lg fn16"
                >ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป</label
              >
            </div>
          </v-row>
        </div>
        <v-row no-gutters class="mt-8">
          <v-col cols="12" class="pa-0">
            <v-btn
              outlined
              dark
              x-large
              class="
                font-weight-bold
                text-capitalize
                rounded-lg
                fn16
                mx-auto
                bg-button
              "
              style="
                width: 120px;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
              @click="next"
            >
              ถัดไป
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="diadlogVet" persistent :overlay-opacity="1">
      <v-card class="pa-4 rounded-lg" height="460">
        <h3 style="color: #07204b" class="text-center">
          กรุณาให้ข้อมูลเพิ่มเติม <br />
          เพื่อที่เราจะได้นำเสอนข้อมูลข่าวสาร <br />
          ได้เหมาะสมกับคุณ
        </h3>
        <v-divider></v-divider>
        <div class="fn16 mt-4" style="color: #07204b">
          <div style="color: #a5a5a5">คุณเป็นสัตวแพทย์ใช่หรือไม่</div>
          <div>
            <v-radio-group v-model="vet" row class="labelFont">
              <v-radio
                label="ใช่"
                class="mr-10"
                value="ใช่"
                fill
                color="#07204b"
              ></v-radio>

              <v-radio
                class="ml-10"
                label="ไม่ใช่"
                value="ไม่ใช่"
                fill
                color="#07204b"
              ></v-radio>
            </v-radio-group>
            <v-divider class="mb-3"></v-divider>
            <span style="color: #a5a5a5">เลขที่ใบประกอบโรคศิลป์</span>
            <!-- <v-text-field
              v-model="vetNumber"
              dense
              outlined
              hide-details
              class="rounded-lg"
              style="border: solid 2px #bcd1ff"
              :disabled="vet === 'ไม่ใช่'"
            /> -->
            <v-row
              justify="center"
              align="center"
              class="ma-0 pa-0 mt-2"
              no-gutters
            >
              <v-col class="ma-0 pa-0">
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[0].value"
                  v-mask="'#'"
                  hide-details
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                />
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[1].value"
                  v-mask="'#'"
                  hide-details
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                />
              </v-col>
              <p style="margin: 2px; font-weight: 800">-</p>
              <v-col cols="4" class="ma-0 pa-0">
                <!-- !a ? '' : a.length < 4 ? 'น้อยกว่า 4' : a.length > 5 ? 'มากกว่า 5' : '' -->
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[2].value"
                  hide-details
                  outlined
                  dense
                  :maxLength="5"
                  :error-messages="
                    !vetNumber[2].value
                      ? ''
                      : vetNumber[2].value.length < 4
                      ? 'น้อยกว่า 4'
                      : vetNumber[2].value.length > 5
                      ? 'มากกว่า 5'
                      : ''
                  "
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                >
                  <template v-slot:label>
                    <span style="font-size: 10px"
                      >ใบอนุญาต4-5หลัก</span
                    ></template
                  >
                  <!-- <template v-slot:append-outer-icon>
                    <div style="margin-inline-start: 0px">/</div>
                  </template> -->
                </v-text-field>
              </v-col>
              <p style="margin: 2px; font-weight: 800">/</p>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[3].value"
                  v-mask="'#'"
                  hide-details
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                />
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[4].value"
                  v-mask="'#'"
                  hide-details
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                />
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[5].value"
                  v-mask="'#'"
                  hide-details
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                />
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  style="font-size: 12px; margin: 0.5px"
                  v-model="vetNumber[6].value"
                  v-mask="'#'"
                  hide-details
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="vet === 'ไม่ใช่'"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3"></v-divider>
            <v-checkbox
              v-model="check"
              class="labelFont"
              color="#07204b"
              style=""
              label="ข้าพเจ้ายืนยันว่าข้อมูลทั้งหมดนี้ถูกต้องและเป็นจริง"
            ></v-checkbox>
          </div>
        </div>
        <v-row no-gutters class="" align="center" justify="center">
          <v-col cols="6">
            <v-btn
              outlined
              dark
              x-large
              class="
                font-weight-bold
                text-capitalize
                rounded-lg
                fn16
                bg-button-cancel
              "
              style="
                width: 120px;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
              @click="back()"
            >
              ย้อนกลับ
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              outlined
              dark
              x-large
              :disabled="!check"
              class="font-weight-bold text-capitalize rounded-lg fn16 bg-button"
              style="width: 120px"
              @click="nextStep()"
            >
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="diadlogConfirm" persistent :overlay-opacity="1">
      <v-card class="pa-4 rounded-lg" height="450">
        <h3 style="color: #07204b" class="text-center">
          กรุณาตรวจสอบความถูกต้องของ <br />
          ข้อมูลของคุณก่อนกดยืนยัน
        </h3>
        <v-divider></v-divider>
        <div class="fn16 mt-4" style="color: #a5a5a5">ชื่อ นามสกุล:</div>
        <div v-if="userData">
          <span style="color: #07204b"
            >{{ userData.firstname }} {{ userData.lastname }}</span
          >
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="fn16 mt-4" style="color: #a5a5a5">ธุรกิจที่คุณสนใจ:</div>
        <div>
          <span style="color: #07204b">{{ selectSpecies }}</span>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="fn16 mt-4" style="color: #a5a5a5">เป็นสัตวแพทย์:</div>
        <div>
          <span style="color: #07204b">{{ vet }}</span>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="fn16 mt-4" style="color: #a5a5a5">
          เลขที่ใบประกอบโรคศิลป์
        </div>
        <div v-if="vet == 'ใช่'">
          <span
            style="color: #07204b"
            v-if="
              vetNumber[0].value &&
              vetNumber[1].value &&
              vetNumber[2].value &&
              vetNumber[3].value &&
              vetNumber[4].value &&
              vetNumber[5].value &&
              vetNumber[6].value
            "
            >{{
              `${vetNumber[0].value}` +
              `${vetNumber[1].value}` +
              "-" +
              `${vetNumber[2].value}` +
              "/" +
              `${vetNumber[3].value}` +
              `${vetNumber[4].value}` +
              `${vetNumber[5].value}` +
              `${vetNumber[6].value}`
            }}</span
          >
          <span style="color: #07204b" v-else>-</span>
        </div>
        <div v-if="vet !== 'ใช่'">
          <span style="color: #07204b">-</span>
        </div>
        <v-divider class="mt-2"></v-divider>
        <v-row no-gutters class="mt-4" align="center" justify="center">
          <v-col cols="6">
            <v-btn
              outlined
              dark
              x-large
              class="
                font-weight-bold
                text-capitalize
                rounded-lg
                fn16
                bg-button-cancel
              "
              style="
                width: 120px;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
              @click="backStep()"
            >
              ย้อนกลับ
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              outlined
              dark
              x-large
              :disabled="!check"
              class="font-weight-bold text-capitalize rounded-lg fn16 bg-button"
              style="width: 120px"
              @click="confirmSave()"
            >
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      dialogSpecies: true,
      diadlogVet: false,
      diadlogConfirm: false,
      check: false,
      vet: "ใช่",
      vetNumber: [
        { num: 0, value: "" },
        { num: 1, value: "" },
        { num: 2, value: "" },
        { num: 3, value: "" },
        { num: 4, value: "" },
        { num: 5, value: "" },
        { num: 6, value: "" },
      ],
      selectSpecies: null,
      headersBrand: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปภาพ", value: "brandImg", align: "center" },
        { text: "ชื่อยี่ห้อ", value: "brandName", align: "center" },
        { text: "รายละเอียด", value: "description", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
      lineId: "",
      userData: null,
    };
  },

  async created() {
    await this.getProfile();
    // await this.getUser();
  },
  beforeCreate() {
    this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {});
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      // this.lineId = "U0354ee79b4cd7f2973027952ad0f6fc5";
      console.log("afterAPI", response);
      this.userData = response.data.data;
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    next() {
      this.dialogSpecies = false;
      this.diadlogVet = true;
    },
    nextStep() {
      this.diadlogVet = false;
      this.diadlogConfirm = true;
    },
    cancel() {
      this.$router.push("/");
    },
    back() {
      this.dialogSpecies = true;
      this.diadlogVet = false;
    },
    backStep() {
      this.diadlogConfirm = false;
      this.diadlogVet = true;
    },
    submit() {
      // this.$router.push("/");
    },
    ViewBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewBrand");
    },
    async confirmSave() {
      // console.log(this.vetNumber);
      this.$swal({
        // text: "คุณยืนยันการบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "info",
        iconColor: "#1a347f",
        html: "<p style='font-size:16px'>คุณยืนยันการบันทึกข้อมูลใช่ หรือไม่ ?</p>",
        template: "#my-template",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#a5a5a5",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        reverseButtons: true,
      }).then(async (result) => {
        // const auth = {
        //   headers: { Authorization: `Bearer ${this.user.token}` },
        // };
        if (result.isConfirmed) {
          //   const auth = {
          //   headers: { Authorization: `Bearer QIBOpfvBEE8VIEJuKt/0yYwCGIFU+JFwLAjgca3cj3yzVYvdz11r9zdje7fVWFQLo5ZIZ5W/jSI/eREpREbyhDFukaBxqss/ccHMwPo10L9jngvyFl3ua5eTviSgqr7S7LUXHWdS6z3A90nE6kGmlgdB04t89/1O/w1cDnyilFU=` },
          // };

          let updateParam = {
            vet_card_id: null,
            type: this.vet,
            species_type: this.selectSpecies,
            royalty_status: "Subscribed",
          };
          if (this.vet == "ใช่") {
            if (
              this.vetNumber[0].value == "" ||
              this.vetNumber[1].value == "" ||
              this.vetNumber[2].value == "" ||
              this.vetNumber[3].value == "" ||
              this.vetNumber[4].value == "" ||
              this.vetNumber[5].value == "" ||
              this.vetNumber[6].value == ""
            ) {
              updateParam.vet_card_id = "";
              const responseUpdateUser = await this.axios.put(
                `${process.env.VUE_APP_API}/users/${this.userData.id}`,
                updateParam
              );
              console.log(responseUpdateUser);

              const response = await this.axios.get(
                `${process.env.VUE_APP_API}/users/updateRichMenu?lineId=${this.lineId}&selectSpecies=${this.selectSpecies}`
              );
              console.log(response);

              if (response.data.response_status == "SUCCESS") {
                this.$swal.fire({
                  icon: "success",
                  iconColor: "#1a347f",
                  text: "บันทึกข้อมูลสำเร็จ",
                  confirmButtonColor: "#1a347f",
                  confirmButtonText: "ตกลง",
                  showConfirmButton: true,
                });
                this.$router.push("/");
              } else {
                this.$swal.fire({
                  icon: "error",
                  text: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              updateParam.vet_card_id =
                `${this.vetNumber[0].value}` +
                `${this.vetNumber[1].value}` +
                "-" +
                `${this.vetNumber[2].value}` +
                "/" +
                `${this.vetNumber[3].value}` +
                `${this.vetNumber[4].value}` +
                `${this.vetNumber[5].value}` +
                `${this.vetNumber[6].value}`;
              console.log(updateParam);
              const responseUpdateUser = await this.axios.put(
                `${process.env.VUE_APP_API}/users/${this.userData.id}`,
                updateParam
              );
              console.log(responseUpdateUser);
              const response = await this.axios.get(
                `${process.env.VUE_APP_API}/users/updateRichMenu?lineId=${this.lineId}&selectSpecies=${this.selectSpecies}`
              );
              console.log(response);

              if (response.data.response_status == "SUCCESS") {
                this.$swal.fire({
                  icon: "success",
                  iconColor: "#1a347f",
                  text: "บันทึกข้อมูลสำเร็จ",
                  confirmButtonColor: "#1a347f",
                  confirmButtonText: "ตกลง",
                  showConfirmButton: true,
                });
                this.$router.push("/");
              } else {
                this.$swal.fire({
                  icon: "error",
                  text: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
          } else if (this.vet !== "ใช่") {
            updateParam.vet_card_id = "-";
            const responseUpdateUser = await this.axios.put(
              `${process.env.VUE_APP_API}/users/${this.userData.id}`,
              updateParam
            );
            console.log(responseUpdateUser);

            const response = await this.axios.get(
              `${process.env.VUE_APP_API}/users/updateRichMenu?lineId=${this.lineId}&selectSpecies=${this.selectSpecies}`
            );
            console.log(response);

            if (response.data.response_status == "SUCCESS") {
              this.$swal.fire({
                icon: "success",
                iconColor: "#1a347f",
                text: "บันทึกข้อมูลสำเร็จ",
                confirmButtonColor: "#1a347f",
                confirmButtonText: "ตกลง",
                showConfirmButton: true,
              });
              this.$router.push("/");
            } else {
              this.$swal.fire({
                icon: "error",
                text: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        }
      });
    },
    goToCreate() {
      this.$router.push("createbrand");
    },
  },
};
</script>

<style scoped lang="scss">
.div-bottom {
  position: fixed;
  left: 0;
  bottom: 130px;
  width: 100%;
  text-align: center;
}
.btn-bottom {
  position: fixed;
  left: 0;
  bottom: 30px;
  text-align: center;
}

.InputGroup {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

input[type="radio"] {
  visibility: hidden;
  height: 0;
  width: 0;
}

label {
  display: block;
  // flex: auto;
  // vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: #1a347f;
  padding: 10px;
  // border-radius: 6px;
  border: solid 2px #bcd1ff;
  user-select: none;
  height: 47px;
  width: 300px;
}

label:last-of-type {
  // margin-right: 0;
}

input[type="radio"]:checked + label {
  background: linear-gradient(268.1deg, #a5a5a5 7.54%, #9f9f9f 100.72%);
  color: #1a347f;
}

input[type="radio"]:hover:not(:checked) + label {
  background-color: white;
  color: #1a347f;
}
.theme--light.v-label {
  color: #1a347f;
  font-size: 12px;
}

.v-input--selection-controls__input {
  background: #07204b;
}

.v-text-field--outlined >>> fieldset {
  border-color: #5096ff;
}
.bg-button {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
.bg-button-cancel {
  background: linear-gradient(268.1deg, #a5a5a5 7.54%, #9f9f9f 100.72%);
}
</style>
